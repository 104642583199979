import React from "react";
import icon_password_hide from "../../Assets/svg/icon_password_hide.svg";
import icon_password_view from "../../Assets/svg/icon_password_view.svg";

import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";

const styleObjectNormal = {
  overflow: "hidden",
  boxSizing: "border-box",
  borderRadius: "7px",
  border: "2px solid #e8ecf4",
  backgroundColor: "#ffffff",
  paddingLeft: "10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
};

const styleObjectError = {
  overflow: "hidden",
  boxSizing: "border-box",
  borderRadius: "7px",
  border: "2px solid red",
  backgroundColor: "#ffffff",
  padding: "10px 10px 10px 10px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
};

const styleObjectPassword = {
  overflow: "hidden",
  boxSizing: "border-box",
  borderRadius: "7px",
  marginBottom: "0px",
  border: "none",
  backgroundColor: "#ffffff",
  paddingLeft: "5px",
  fontSize: "16px",
  color: "#000000",
  textAlign: "left",
  width: "100%",
};

const stylePasswordNormal = {
  borderRadius: "7px",
  border: "2px solid #e8ecf4",
  backgroundColor: "#ffffff",
};

const stylePasswordError = {
  borderRadius: "7px",
  border: "2px solid red",
};

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  list,
  id,
  toggleShowPassword,
  isPassword,
  list_array,
  pattern,
  defaultValue,
  Title,
}) => {
  const language = localStorage?.language ? localStorage?.language : "ina";
  let title = Title ? Title : placeholder;
  if (error) {
    title = dict?.[error]?.[language] ? dict?.[error]?.[language] : error;
  }
  let itemContent;
  if (isPassword) {
    itemContent = (
      <div className="input_old">
        <div
          style={{
            textAlign: "left",
            color: error ? "red" : "#1265AE",
            fontSize: "1rem",
            fontWeight: "600",
            marginTop: "10px",
          }}
        >
          {title}
        </div>
        <div
          className="input_icon"
          style={error ? stylePasswordError : stylePasswordNormal}
        >
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            pattern={pattern}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            style={styleObjectPassword}
            list={list ? list : ""}
            autoComplete="off"
            data-mapid="insertFormPw"
          />
          <img
            className="pointer"
            alt="show password"
            src={type === "password" ? icon_password_view : icon_password_hide}
            width="25px"
            onClick={toggleShowPassword}
          />
        </div>
        {info && <small>{info}</small>}
      </div>
    );
  } else {
    if (isEmpty(list_array)) {
      itemContent = (
        <div className="input_old">
          <div
            style={{
              textAlign: "left",
              color: error ? "red" : "#1265AE",
              fontSize: "1rem",
              fontWeight: "600",
              marginTop: "10px",
            }}
          >
            {title}
          </div>
          <input
            data-mapid="insertFormId"
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            pattern={pattern}
            disabled={disabled}
            style={error ? styleObjectError : styleObjectNormal}
            list={list ? list : ""}
            autoComplete="off"
          />
          {info && <small>{info}</small>}
        </div>
      );
    } else {
      itemContent = (
        <div className="input_old">
          <div
            style={{
              textAlign: "left",
              color: error ? "red" : "#1265AE",
              fontSize: "1rem",
              fontWeight: "600",
              marginTop: "10px",
            }}
          >
            {title}
          </div>
          <input
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
            name={name}
            id={id}
            pattern={pattern}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={error ? styleObjectError : styleObjectNormal}
            list={list}
            autoComplete="off"
          />
          <datalist id={list}>
            {list_array.map((element, idx) => {
              return <option value={element} key={idx} />;
            })}
          </datalist>
          {info && <small>{info}</small>}
        </div>
      );
    }
  }
  return itemContent;
};

TextFieldGroup.defaultProps = {
  type: "text",
};

export default TextFieldGroup;

export const SelectField = ({
  name,
  placeholder,
  value,
  error,
  info,
  onChange,
  disabled,
  id,
  list_array,
}) => {
  const language = localStorage?.language ? localStorage?.language : "ina";
  let title = placeholder;
  if (error) {
    title = dict?.[error]?.[language] ? dict?.[error]?.[language] : error;
  }
  return (
    <div className="input_old">
      <div
        style={{
          textAlign: "left",
          color: error ? "red" : "#1265AE",
          fontSize: "1rem",
          fontWeight: "600",
          marginTop: "10px",
        }}
      >
        {title}
      </div>
      <select
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={error ? styleObjectError : styleObjectNormal}
        data-mapid="dropdown_profile"
      >
        <option value="" hidden>
          {dict?.["Choose"]?.[language]}
        </option>
        {/* Choose industry | Silakan pilih industri */}
        {list_array.map((item, index) => {
          return (
            <option value={item.value} key={index}>
              {item.title}
            </option>
          );
        })}
      </select>
      {info && <small>{info}</small>}
    </div>
  );
};
