//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import TextFieldGroup from "../common_input/TextFieldGroup";

//Redux function
import { loginUser } from "../../App/actions/authActions";

//Picture Asset
//General Function
import dict from "../../Data/dict.json";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      password: "",
      errors: {},
      show_password: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const isNav = this.props.isNav ? this.props.isNav : null;
    const userData = {
      name: this.state.name,
      password: this.state.password,
      isNav,
    };
    this.props.loginUser(userData);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { errors, name, password, show_password } = this.state;

    const { loading } = this.props.auth;

    let iconButton = (
      <button
        className="button"
        type="submit"
        style={{ padding: "5px 15px", backgroundColor: "#1265ae" }}
        data-mapid="clickLogin"
      >
        {dict["Sign in"][language]}
      </button>
    );
    if (loading) {
      iconButton = (
        <div
          className="button"
          style={{ padding: "5px 15px", backgroundColor: "#1265ae" }}
        >
          {dict["Sign in..."][language]}
        </div>
      );
    }
    let content = (
      <form noValidate onSubmit={this.onSubmit}>
        <TextFieldGroup
          Title={dict["User name"][language]}
          placeholder={dict["Enter your username/email"][language]}
          name="name"
          id="name"
          value={name}
          onChange={this.onChange}
          error={errors.name}
          className="input_border"
        />
        <br />
        <TextFieldGroup
          type={show_password ? "text" : "password"}
          isPassword={true}
          Title={dict["Password"][language]}
          placeholder={dict["Enter Your Password"][language]}
          name="password"
          id="password"
          value={password}
          onChange={this.onChange}
          toggleShowPassword={this.toggleShowPassword}
          pattern="[0-9a-zA-Z]{8,}"
          error={errors.password}
          className="input_border"
        />
        <div className="center_right forget_password">
          <Link to="/forget-password">
            <p onClick={this.toggleForgetPassword}>
              {dict["Forgot Password"][language]}?
            </p>
          </Link>
        </div>
        {iconButton}
      </form>
    );

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
})(Login);
