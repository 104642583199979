//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import TextFieldGroup, {
  SelectField,
} from "../../Components/common_input/TextFieldGroup";

//Redux function
import {
  edit_text_profile,
  edit_text_profile_2,
} from "../../App/actions/authActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";

class EditProfile extends Component {
  state = {
    userId: "",
    bio: "",
    fullname: "",
    dateofbrithday: "",
    phonenumber: "",
    industry: "",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      Object.entries(nextProps.auth.user).length !== 0 &&
      nextProps.auth.user.constructor === Object &&
      nextProps.auth.user._id !== prevState.userId
    ) {
      const user = nextProps.auth.user;
      const userId = user._id;
      const bio = user.bio;
      const fullname = user.full_name;
      const dateofbrithday = user.birthday;
      const phonenumber = user.phone_number;
      const industry = user.industry;
      return {
        userId,
        bio,
        fullname,
        dateofbrithday,
        phonenumber,
        industry,
      };
    } else return null;
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { bio, fullname, dateofbrithday, phonenumber, industry } = this.state;
    const body = {
      bio,
    };
    this.props.edit_text_profile(body);
    const body_2 = {
      full_name: fullname,
      birthday: dateofbrithday,
      phone_number: phonenumber,
      industry,
    };
    this.props.edit_text_profile_2(body_2);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { bio, fullname, dateofbrithday, phonenumber, industry } = this.state;
    const { loading, itemLoading } = this.props.auth;
    let button = (
      <button
        type="submit"
        className="save_button"
        onClick={this.handleSubmit}
        data-mapid="clickSave"
      >
        {dict["Save"][language]}
      </button>
    );
    if (loading & (itemLoading === "edit_text_profile")) {
      button = (
        <div className="button_standard">{dict["Saving"][language]}...</div>
      );
    }
    const industry_list = [
      {
        value: "Information Technology and Services",
        title: dict["Information Technology and Services"][language],
      },
      {
        value: "Government",
        title: dict["Government"][language],
      },
      {
        value: "Real Estate and Architecture",
        title: dict["Real Estate and Architecture"][language],
      },
      {
        value: "Mining and Metals",
        title: dict["Mining and Metals"][language],
      },
      {
        value: "Oil and Energy",
        title: dict["Oil and Energy"][language],
      },
      {
        value: "Research and Education",
        title: dict["Research and Education"][language],
      },
      {
        value: "Nonprofit Organization Management",
        title: dict["Nonprofit Organization Management"][language],
      },
      {
        value: "Internet and Telecommunications",
        title: dict["Internet and Telecommunications"][language],
      },
      {
        value: "Farming and Agriculture",
        title: dict["Farming and Agriculture"][language],
      },
      {
        value: "Banking and Financial Services",
        title: dict["Banking and Financial Services"][language],
      },
      {
        value: "Consumer Goods",
        title: dict["Consumer Goods"][language],
      },
      {
        value: "Logistics and Supply Chain",
        title: dict["Logistics and Supply Chain"][language],
      },
      {
        value: "Retail and Fashion",
        title: dict["Retail and Fashion"][language],
      },
      {
        value: "Manufacture",
        title: dict["Manufacture"][language],
      },
      {
        value: "Transportation and Mobility",
        title: dict["Transportation and Mobility"][language],
      },
      {
        value: "Food and Beverages",
        title: dict["Food and Beverages"][language],
      },
      {
        value: "Hospital and Health Care",
        title: dict["Hospital and Health Care"][language],
      },
      {
        value: "Hospitality, Entertainment, and Tourism",
        title: dict["Hospitality, Entertainment, and Tourism"][language],
      },
      {
        value: "Media and Public Relations",
        title: dict["Media and Public Relations"][language],
      },
      {
        value: "Fishery and Marine",
        title: dict["Fishery and Marine"][language],
      },
    ];
    const itemContent = (
      <div>
        <h1 className="title_modal_new">Edit User</h1>
        <div className="edit_profile_row">
          <TextFieldGroup
            placeholder={dict["Full name"][language]}
            name="fullname"
            id="fullname"
            value={fullname}
            onChange={this.handleChange}
          />
          <TextFieldGroup
            placeholder="bio"
            name="bio"
            id="bio"
            value={bio}
            onChange={this.handleChange}
          />
          <TextFieldGroup
            placeholder={dict["date of birth"][language]}
            type="date"
            name="dateofbrithday"
            id="dateofbrithday"
            value={dateofbrithday}
            onChange={this.handleChange}
          />
          <TextFieldGroup
            placeholder={dict["phone number"][language]}
            name="phonenumber"
            id="phonenumber"
            pattern="[0-9]{11,13}"
            value={phonenumber}
            onChange={this.handleChange}
          />
          <SelectField
            list_array={industry_list}
            name="industry"
            id="industry"
            value={industry}
            onChange={this.handleChange}
            placeholder={dict["industry"][language]}
          />
          <div style={{ width: "120px" }}>{button}</div>
        </div>
      </div>
    );
    return <div className="main_container">{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  edit_text_profile,
  edit_text_profile_2,
})(EditProfile);
