/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Draw from "@urbica/react-map-gl-draw";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_layer,
  pushFeature,
  pushFeatureV2,
  editGeometry,
  editGeometryV2,
  deleteFeature,
} from "../../App/actions/layerActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import { drawStyle } from "../layer/DrawStyle";

/*NON IMPORT*/

class LAYER_DRAWING extends Component {
  state = {
    content_render: <></>,
    feature_key_selected: "",
    features_length_before: 0,
    features_length_after: 0,
  };

  componentDidMount() {
    this.on_start();
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    if (status_action_after !== status_action_before) {
      this.on_start();
    }
    const current_type_after = this.props.layer.current_type;
    const current_type_before = prevProps.layer.current_type;
    if (current_type_after !== current_type_before) {
      this.on_rerender();
    }
  }

  componentWillUnmount() {
    this.on_reset();
  }

  on_rerender = () => {
    this.setState(
      {
        content_render: <></>,
      },
      () => {
        const { geojson_edit_geometry, mode_draw_geometry, current_type } =
          this.props.layer;
        this.setState({
          content_render: (
            <main>
              {["Point", "LineString", "Polygon"].includes(current_type) && (
                <Draw
                  data={geojson_edit_geometry}
                  mode={mode_draw_geometry}
                  styles={drawStyle}
                  onDrawCreate={this.on_draw_create}
                  onDrawUpdate={this.on_draw_update}
                  onChange={this.on_change}
                  onDrawModeChange={this.on_draw_mode_change}
                  onDrawSelectionChange={this.on_draw_selection_change}
                  pointControl={current_type === "Point" ? true : false}
                  lineStringControl={
                    current_type === "LineString" ? true : false
                  }
                  polygonControl={current_type === "Polygon" ? true : false}
                  trashControl={true}
                  combineFeaturesControl={false}
                  uncombineFeaturesControl={false}
                />
              )}
            </main>
          ),
        });
      }
    );
  };

  on_start = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const geojson = {
      type: "FeatureCollection",
      features: [],
    };
    const geo_layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const geojson_edit_geometry_value = geo_layer?.geojson || geojson;
    const architecture = geo_layer?.architecture;
    const type = geo_layer?.type;

    if (architecture && type) {
      this.props.set_value_layer({
        key: "current_type",
        value: type,
      });
      this.props.set_value_layer({
        key: "geojson_edit_geometry",
        value: geojson_edit_geometry_value,
      });
      this.props.set_value_layer({
        key: "current_architecture",
        value: architecture,
      });
      /*
      'draw_point',
      'draw_line_string',
      'draw_polygon',
      'simple_select',
      'direct_select',
      'static',
      'trash'

      const array_mode_type = [
        { type: "Point", mode: "draw_point" },
        { type: "LineString", mode: "draw_line_string" },
        { type: "Polygon", mode: "draw_polygon" },
        { type: "MultiPoint", mode: "draw_point" },
        { type: "MultiLineString", mode: "draw_line_string" },
        { type: "MultiPolygon", mode: "draw_polygon" },
      ];      
      */
      this.props.set_value_layer({
        key: "mode_draw_geometry",
        value: "simple_select",
      });
      const { mode_draw_geometry } = this.props.layer;
      this.setState({
        content_render: (
          <main>
            {["Point", "LineString", "Polygon"].includes(type) && (
              <Draw
                data={geojson_edit_geometry_value}
                mode={mode_draw_geometry}
                styles={drawStyle}
                onDrawCreate={this.on_draw_create}
                onDrawUpdate={this.on_draw_update}
                onChange={this.on_change}
                onDrawModeChange={this.on_draw_mode_change}
                onDrawSelectionChange={this.on_draw_selection_change}
                pointControl={type === "Point" ? true : false}
                lineStringControl={type === "LineString" ? true : false}
                polygonControl={type === "Polygon" ? true : false}
                trashControl={true}
                combineFeaturesControl={false}
                uncombineFeaturesControl={false}
              />
            )}
          </main>
        ),
      });
    }
  };

  on_reset = () => {
    this.props.set_value_layer({
      key: "mode_draw_geometry",
      value: "simple_select",
    });
  };

  on_draw_create = (event) => {
    // console.log("on_draw_create", event);

    const geometry = event.features[0].geometry;
    const id = event.features[0].id;

    //panggil API pushFeature
    const { layer_id, current_architecture } = this.props.layer;
    let feature = {
      type: "Feature",
      properties: { key: id },
      geometry: geometry,
      key: id,
      isHide: false,
      formStatus: { status: "digitasi" },
    };
    const body = {
      geo_layer_id: layer_id,
      feature,
    };
    if (current_architecture === "v2") {
      this.props.pushFeatureV2(body);
    } else {
      this.props.pushFeature(body);
    }
  };

  on_draw_update = (event) => {
    // console.log("on_draw_update", event);

    //panggil API editGeometry
    if (["change_coordinates", "move"].includes(event?.action)) {
      const feature_key =
        event?.features?.[0]?.properties?.key || event?.features?.[0]?.id;
      const geometry = event?.features?.[0]?.geometry;
      const { layer_id, current_architecture } = this.props.layer;
      if (feature_key && geometry && layer_id) {
        const body = {
          feature_key: feature_key,
          geo_layer_id: layer_id,
          geometry: geometry,
        };
        if (current_architecture === "v2") {
          this.props.editGeometryV2(body);
        } else {
          this.props.editGeometry(body);
        }
      }
    }
  };

  on_change = (event) => {
    // console.log("on_change", event);

    const features_length_after = event?.features?.length;
    this.setState(
      {
        features_length_after,
      },
      () => {
        if (
          this.state.features_length_after < this.state.features_length_before
        ) {
          //panggil API deleteFeature
          const body = {
            feature_key: this.state.feature_key_selected,
            geo_layer_id: this.props.layer.layer_id,
          };
          this.props.deleteFeature(body);
        }
      }
    );
  };

  on_draw_mode_change = (event) => {
    // console.log("on_draw_mode_change", event);
  };

  on_draw_selection_change = (event) => {
    // console.log("on_draw_selection_change", event);

    const feature_key = event?.features?.[0]?.properties?.key;
    const { layer_id, geo_layer_list } = this.props.layer;
    const features_length_before =
      geo_layer_list?.find((item) => item?.geo_layer?._id === layer_id)
        ?.geo_layer?.geojson?.features?.length || 0;
    this.setState({
      feature_key_selected: feature_key,
      features_length_before: features_length_before,
    });
  };

  render() {
    //local storage

    //local state
    const { content_render } = this.state;

    //global props

    //content

    return <>{content_render}</>;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value_layer,
  pushFeature,
  pushFeatureV2,
  editGeometry,
  editGeometryV2,
  deleteFeature,
})(LAYER_DRAWING);
