/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import MapGL, {
  Image,
  GeolocateControl,
  NavigationControl,
  AttributionControl,
} from "@urbica/react-map-gl";
import bbox from "@turf/bbox";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";

/*PERSONAL COMPONENT*/
//pop up
import RenderPopupEditor from "./RenderPopupEditor";
//draw
import TOOLBOX_DRAWING from "../map_drawing/TOOLBOX_DRAWING";
import SINI_DRAWING from "../map_drawing/SINI_DRAWING";
import LAYER_DRAWING from "../map_drawing/LAYER_DRAWING";
//layer
import LAYER_BASIC from "./LAYER_BASIC";
import LAYER_SELECTED from "./LAYER_SELECTED";
import TOOLBOX_LAYER from "./TOOLBOX_LAYER";
import LAYER_GENANGAN from "./LAYER_GENANGAN";
import STATUS_GET_GENANGAN from "./STATUS_GET_GENANGAN";
import LayerInsight from "./LayerInsight";
import SINI_MARKER from "../layer_sini/SINI_MARKER";
import LAYER_SINI_CENTER from "../layer_sini/LAYER_SINI_CENTER";
import LAYER_SINI_DEMOGRAPHY_ONE from "../layer_sini/LAYER_SINI_DEMOGRAPHY_ONE";
import LAYER_SINI_LANDVALUE from "../layer_sini/LAYER_SINI_LANDVALUE";
import LAYER_SINI_LANDZONE from "../layer_sini/LAYER_SINI_LANDZONE";
import LAYER_SINI_FLOOD from "../layer_sini/LAYER_SINI_FLOOD";
import LAYER_SINI_LANDSLIDE from "../layer_sini/LAYER_SINI_LANDSLIDE";
import LAYER_SINI_TSUNAMI from "../layer_sini/LAYER_SINI_TSUNAMI";
import LAYER_SINI_RDTR_ONE from "../layer_sini/LAYER_SINI_RDTR_ONE";
import LAYER_SINI_DEMOGRAPHY_NEIGHBOR from "../layer_sini/LAYER_SINI_DEMOGRAPHY_NEIGHBOR";
import LAYER_SINI_LANDVALUE_NEIGHBOR from "../layer_sini/LAYER_SINI_LANDVALUE_NEIGHBOR";
import LAYER_SINI_LANDZONE_NEIGHBOR from "../layer_sini/LAYER_SINI_LANDZONE_NEIGHBOR";
import LAYER_SINI_FLOOD_NEIGHBOR from "../layer_sini/LAYER_SINI_FLOOD_NEIGHBOR";
import LAYER_SINI_LANDSLIDE_NEIGHBOR from "../layer_sini/LAYER_SINI_LANDSLIDE_NEIGHBOR";
import LAYER_SINI_RDTR_NEIGHBOR from "../layer_sini/LAYER_SINI_RDTR_NEIGHBOR";
import LAYER_SINI_POI from "../layer_sini/LAYER_SINI_POI";
import LAYER_SINI_ISOCHRONE from "../layer_sini/LAYER_SINI_ISOCHRONE";
// import LAYER_CURSOR from "./LAYER_CURSOR";

/*REDUX FUNCTION*/
import { setDrawMode } from "../../App/actions/layerNewActions";
import { set_map } from "../../App/actions/mapActions";
import { getSDATelemetry } from "../../App/actions/iotActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action, set_value_map } from "../../App/actions/mapActions";
import { state_update } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import * as Assets from "../../Assets";
import { icon_map } from "../../Assets";

/*GENERAL*/
import isEmpty from "../../App/validation/is-empty";
import { gometry_to_area_or_length } from "../../App/validation/geometry_to_area_or_length";
import { domain_mapid_basemap } from "../../App/validation/features_domain";
import basemap_styles from "../../App/validation/basemap_styles";

/*NON IMPORT*/
let drawingshape;
const MAPID_COLOR1 = "#37A0F7"; //untuk memberikan warna ke ikon edit geometry bawaan urbica mapbox gl

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render_content: null,
      //map
      viewport: {
        latitude: -0.811123, // -0.435161, 118.009574 tengah
        longitude: 113.921327,
        zoom: 4,
      },
      drag: {
        cliked: false,
        screenX: 10,
        screenY: 10,
      },
      mapMode: "street",
      geojson: { type: "FeatureCollection", features: [] },
      selectedFeatures: [],
      //geojson draw
      data: {
        type: "FeatureCollection",
        features: [],
      },
      pickCoord: [0, 0],
      //Mouse Position
      latLong: { lat: 0, lng: 0 },
    };
    this._map = React.createRef();
    this._draw = React.createRef();
    this.geocoderContainerRef = React.createRef();
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    this.set_basemap_api();
    this.on_load_map();
    this.props.state_update();
  }

  static getDerivedStateFromProps(nextProps) {
    const { layer_id, geo_layer_list } = nextProps.layer;
    const layer = geo_layer_list?.find((l) => l.geo_layer?._id === layer_id);
    const typeLayer = layer?.geo_layer?.type;
    return {
      typeLayer,
    };
  }

  componentDidUpdate(prevProps) {
    const { toolbox_mode } = this.props.layer;
    // Draw
    const geometryStatus_after = this.props.layer.geometryStatus;
    const geometryStatus_before = prevProps.layer.geometryStatus;
    // Toolbox
    const toolbox_modeBefore = this.props.layer.toolbox_mode;
    const toolbox_modeAfter = prevProps.layer.toolbox_mode;
    // Drawmode
    const draw_mode_before = this.props.layer.draw_mode;
    const draw_mode_after = prevProps.layer.draw_mode;
    if (geometryStatus_after !== geometryStatus_before) {
      const { layer_id, geo_layer_list } = this.props.layer;
      const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
      const geojson = layer?.geo_layer?.geojson
        ? layer?.geo_layer?.geojson
        : { type: "FeatureCollection", features: [] };
      this.setState({ geojson });
    }
    if (
      toolbox_modeBefore !== toolbox_modeAfter ||
      geometryStatus_before !== geometryStatus_after ||
      draw_mode_before !== draw_mode_after
    ) {
      if (toolbox_mode === "buffer_point") {
        this.setState({ mode: "draw_point" });
      } else if (toolbox_mode === "distance" || toolbox_mode === "elevation") {
        this.setState({ mode: "draw_line_string" });
      } else if (toolbox_mode === "area") {
        this.setState({ mode: "draw_polygon" });
      } else {
        this.setState({ mode: "simple_select" });
        this.props.setDrawMode("simple_select");
      }
    }
    //untuk mencegah map masih null karena switch basemap
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const map = this?.props?.map?.map;
    if (state_update_after !== state_update_before && !map) {
      this.on_load_map();
    }
  }

  set_basemap_api = () => {
    this.props.set_value_map({
      key: "map_ready",
      value: false,
    });
    const { domain } = this.props.auth;
    const active_basemap = domain_mapid_basemap.includes(domain)
      ? "mapid"
      : "mapbox";
    const default_by_domain =
      active_basemap === "mapid" ? "street-2d-building" : "street";
    let basemap_props = basemap_styles().find(
      (item) => item?.value === default_by_domain
    );
    const basemap_api = basemap_props?.api?.[active_basemap];
    this.props.set_value_map({
      key: "basemap_mode",
      value: default_by_domain,
    });
    this.props.set_value_map({
      key: "basemap_api",
      value: basemap_api,
    });
    // remove logo mapbox
    if (active_basemap === "mapid") {
      const element =
        document.getElementsByClassName("mapboxgl-ctrl-logo")?.[0];
      if (element) element.remove();
    }
    this.props.set_value_map({
      key: "map_ready",
      value: true,
    });
    this.on_load_map();
  };

  on_load_map = () => {
    if (this?._map?.current) {
      const map = this._map.current.getMap();
      map.once("load", () => {
        let isDrawing = (md) => {
          return (
            md === "draw_polygon" ||
            md === "draw_line_string" ||
            md === "draw_point"
          );
        };
        map.on("draw.modechange", (event) => {
          if (
            event.mode === "direct_select" &&
            isEmpty(this.state.data.features)
          ) {
          }
          try {
            //memberikan warna
            //untuk memberikan warna ke ikon edit geometry bawaan urbica mapbox gl
            //lewat belakang
            if (isDrawing(event.mode)) {
              let el =
                event.mode === "draw_point"
                  ? document.getElementsByClassName(
                      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point active"
                    )
                  : event.mode === "draw_line_string"
                  ? document.getElementsByClassName(
                      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line active"
                    )
                  : document.getElementsByClassName(
                      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon active"
                    );
              if (el?.[0]) {
                el[0].style.backgroundColor = MAPID_COLOR1;
              }
              drawingshape = event.mode;
            } else {
              let el =
                drawingshape === "draw_point"
                  ? document.getElementsByClassName(
                      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point"
                    )
                  : drawingshape === "draw_line_string"
                  ? document.getElementsByClassName(
                      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line"
                    )
                  : document.getElementsByClassName(
                      "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
                    );
              if (el?.[0]) {
                el[0].style.backgroundColor = "transparent";
              }
            }
          } catch (error) {}
        });
        this.props.set_map(this._map.current.getMap());
      });
    }
  };

  handleMouseMove = (e) => {
    this.setState({ latLong: { lat: e.lngLat.lat, lng: e.lngLat.lng } });
  };

  //Buat nampilin popup ketika salah satu point || line || polygon diklik
  layerOnClick = (event) => {
    const { geometryStatus } = this.props.layer;
    const { draw_toolbox_status } = this.props.toolbox;
    if (!geometryStatus && !draw_toolbox_status) {
      this.props.set_value_properties({
        key: "popupInfo",
        value: null,
      });
      this.props.set_value_properties({
        key: "modal_pop_up_layer",
        value: true,
      });
      this.generatePopup(event);
    }
  };

  generatePopup = (event) => {
    const { geo_layer_list } = this.props.layer;
    const { from } = this.props;
    const { map } = this.props?.map;
    const layer_id_popup = event?.features?.[0]?.layer?.id;
    // mencari data layer dari id layer yang dipilih
    let layer_popup = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id_popup
    );
    // mengambil data id dari layer yang dipilih
    let dataPopUP = event?.features?.[0]?.properties;
    if (layer_popup !== undefined) {
      // menyisipkan geometry
      dataPopUP["_geometry"] = gometry_to_area_or_length(
        layer_popup.geo_layer?.geojson?.features?.find(
          (feature) => feature.properties.key === dataPopUP.key
        ).geometry
      );
      this.props.set_value_properties({
        key: "feature_key_selected",
        value: event?.features?.[0]?.properties?.key,
      });
      // mengambil data fields dari layer yang dipilih
      let fields = layer_popup.geo_layer.fields;
      const type_2 = layer_popup.geo_layer.type_2;
      const features = layer_popup?.geo_layer?.geojson?.features || [];
      //handle untuk titik masukan long lat ke dalam properties supaya bisa diakses lewat pop up, karena long lat di event ketika klik peta bisa jadi beda dengan long lat milik feature (hanya untuk titik)
      let geometry = event?.features[0]?.geometry;
      const feature = features.find((item) => item.key === dataPopUP.key);
      geometry = feature.geometry;
      this.props.set_value_properties({
        key: "feature_object_selected",
        value: feature,
      });
      const genangan_cm =
        event?.features[0]?.properties?.Ketinggian_Genangan_cm;
      // Fly To
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: geometry,
          },
        ],
      };
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      const padding = { top: 150, bottom: 300, left: 400, right: 400 };
      if (map) {
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: padding,
            maxZoom: 22,
          }
        );
      }
      //menggunakan nilai center supaya ketika yang diklik poligon, pop upnya muncul di tengah poligon
      const center_lat = (maxLat + minLat) / 2;
      const center_long = (maxLng + minLng) / 2;
      // menerapkan popup pada mapbox untuk ditampilkan berdasarkan layer yang dipilih
      let popupInfo = (
        <RenderPopupEditor
          lat={center_lat}
          long={center_long}
          fields={fields}
          dataPopUP={dataPopUP}
          type_2={type_2}
          feature_key={dataPopUP.key}
          genangan_cm={genangan_cm}
          geo_layer={layer_popup?.geo_layer}
          is_editable={from === "editor"}
        />
      );
      this.props.set_value_properties({
        key: "popupInfo",
        value: popupInfo,
      });
    }
    this.props.status_action();
  };

  setOnChange(data) {
    try {
      this.setState({ data: data });
    } catch (error) {}
  }

  // Merender Icon
  generateMapIcon() {
    const listIcon = Object.keys(icon_map).map((key) => (
      <Image key={key} id={key} image={icon_map[key]} />
    ));
    return listIcon;
  }

  render() {
    //local storage

    //local state

    //global props
    const { geometryStatus } = this.props.layer;
    const { basemap_api, map_ready, mapbox_api_key } = this.props.map;
    const { popupInfo, modal_pop_up_layer } = this.props.properties;
    const { draw_toolbox_status } = this.props.toolbox;
    const { sini_choose_map_active } = this.props.sini;

    //content
    let content;
    if (map_ready) {
      content = (
        <MapGL
          onMousemove={(e) => {
            this.handleMouseMove(e);
          }}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          mapStyle={basemap_api}
          accessToken={mapbox_api_key}
          latitude={this.state.viewport.latitude}
          longitude={this.state.viewport.longitude}
          zoom={this.state.viewport.zoom}
          maxZoom={22}
          minZoom={0}
          onPitch={(e) => {
            e.target.transform.maxPitch = 60;
          }}
          onViewportChange={(e) => {
            return;
          }}
          attributionControl={false}
          {...this.props}
          ref={this._map}
        >
          {draw_toolbox_status && <TOOLBOX_DRAWING />}
          {sini_choose_map_active && <SINI_DRAWING />}
          {geometryStatus && <LAYER_DRAWING />}
          {modal_pop_up_layer && popupInfo}
          <Image id="cerah" image={Assets.icon.iconCerah} />
          <Image id="ringan" image={Assets.icon.iconRingan} />
          <Image id="sedang" image={Assets.icon.iconRingan} />
          <Image id="lebat" image={Assets.icon.iconLebat} />
          <Image id="sangatlebat" image={Assets.icon.iconLebat} />
          <Image id="normal" image={Assets.icon.iconCerah} />
          <Image id="Offline" image={Assets.icon.offline} />
          {/* <LAYER_CURSOR latLong={latLong} /> */}
          <LAYER_SELECTED />
          <LAYER_GENANGAN layerOnClick={this.layerOnClick} />
          <LayerInsight layerOnClick={this.layerOnClick} />
          <TOOLBOX_LAYER />
          <LAYER_BASIC layerOnClick={this.layerOnClick} />
          <SINI_MARKER />
          <LAYER_SINI_CENTER />
          <LAYER_SINI_DEMOGRAPHY_ONE />
          <LAYER_SINI_LANDVALUE />
          <LAYER_SINI_LANDZONE />
          <LAYER_SINI_FLOOD />
          <LAYER_SINI_LANDSLIDE />
          <LAYER_SINI_TSUNAMI />
          <LAYER_SINI_RDTR_ONE />
          <LAYER_SINI_DEMOGRAPHY_NEIGHBOR />
          <LAYER_SINI_LANDVALUE_NEIGHBOR />
          <LAYER_SINI_LANDZONE_NEIGHBOR />
          <LAYER_SINI_FLOOD_NEIGHBOR />
          <LAYER_SINI_LANDSLIDE_NEIGHBOR />
          <LAYER_SINI_RDTR_NEIGHBOR />
          <LAYER_SINI_POI />
          <LAYER_SINI_ISOCHRONE />
          <NavigationControl showCompass showZoom position="top-right" />
          <GeolocateControl position="top-right" />
          <AttributionControl
            position="bottom-right"
            customAttribution={`Geomapid V ${this.props.auth.version} | <a href="https://mapid.io/">© MAPID</a> | © MapBox`}
          />
        </MapGL>
      );
    } else {
      content = <></>;
    }

    return (
      <main
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        {content}
        <STATUS_GET_GENANGAN />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  auth: state.auth,
  properties: state.properties,
  toolbox: state.toolbox,
  sini: state.sini,
});

export default connect(mapStateToProps, {
  set_map,
  getSDATelemetry,
  setDrawMode,
  set_value_properties,
  status_action,
  set_value_map,
  state_update,
})(Map);
