/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";

/*PERSONAL COMPONENT*/
import EditLayer from "./EditLayer";
import DeleteLayer from "./DeleteLayer";
import DeleteLayerAttach from "./DeleteLayerAttach";
import AddRowBulk from "./AddRowBulk";
import Modal from "../common_modal/Modal";
import EditIcon from "../common_icon/EditIcon";
import DeleteIcon from "../common_icon/DeleteIcon";
import CheckBox from "../common_input/CheckBox";
import LongPress from "../common_trivial/LongPress";

/*REDUX FUNCTION*/
import {
  setLayer,
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
  setLayerWOgeojson,
  setChatStatus,
  getDetailLayerByLinkWOgeojson,
  setViewLayer,
} from "../../App/actions/layerActions";
import {
  getDetailLayerById,
  migration_action,
  setDrawMode,
} from "../../App/actions/layerNewActions";
import { get_pda, get_pch } from "../../App/actions/iotActions";
import { get_layer_detail_custom_api } from "../../App/actions/openApiActions";
import {
  view_layer,
  set_layer_id,
  view_heatmap,
  set_value_layer,
} from "../../App/actions/dataActions";
import { set_value_toolbox } from "../../App/actions/toolboxActions";
import { openModal } from "../../App/actions/authActions";

/*PICTURE ASSET*/
import icon_view from "../../Assets/svg/icon_view.svg";
import icon_view_white from "../../Assets/svg/icon_view_white.svg";
import icon_edit_geometry from "../../Assets/svg/icon_edit_geometry.svg";
import icon_edit_geometry_white from "../../Assets/svg/icon_edit_geometry_white.svg";
import icon_edit_properties from "../../Assets/svg/icon_edit_properties.svg";
import icon_edit_properties_white from "../../Assets/svg/icon_edit_properties_white.svg";
import icon_heatmap_black from "../../Assets/svg/icon_heatmap_black.svg";
import icon_heatmap_white from "../../Assets/svg/icon_heatmap_white.svg";
import icon_chat from "../../Assets/svg/icon_chat.svg";
import icon_chat_white from "../../Assets/svg/icon_chat_white.svg";
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

//const SOCKET_URL = "http://localhost:5000";
const SOCKET_URL_MAPID = "https://socket.mapid.io";
const geo_layer_status_that_editable_properties = [
  "original",
  "digitasi",
  "upload",
];
const geo_layer_status_that_editable_layer = [
  "original",
  "digitasi",
  "upload",
  "attached",
];
const geo_layer_status_that_deleteable = [
  "original",
  "digitasi",
  "upload",
  "attached",
];

class LayerListRender extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.interval = null;
    this.state = {
      modalEdit: false,
      modalDelete: false,
      modalDeleteAttach: false,
      realtimeState: false,
      modalMigration: false,
      modalEditorInfo: false,
      modalEditorData: null,
      bodyMigrate: {
        geo_layer_id: "",
        user_id: "",
        geo_project_id: "",
        buttonOnStatus: { status: false, IdLayer: "" },
      },
      editorUsers: [],
      IDLayer: "",
    };
    this.maxViewEditors = 3;
    this.socket = io.connect(SOCKET_URL_MAPID, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    clearInterval(this.interval);
  }

  getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  handleModalEditorUser(user) {
    const { modalEditorInfo } = this.state;
    this.setState({
      modalEditorInfo: !modalEditorInfo,
      modalEditorData: user ? user : null,
    });
  }

  handleBeforeUnload = (event) => {
    const { _id, name, email, profile_picture } = this.props.auth?.user || {};
    const user_data = { _id, name, email, profile_picture };

    this.socket.emit("from_mapid_backend", {
      data: { ...user_data },
      topic: "userDeleteAllLayer",
    });
  };

  //1
  toggle_fly_layer = (geo_layer_item, folder_id) => {
    const { layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    if (
      //layer yang dipilih bukanlah layer yang sedang aktif
      layer_id !== geo_layer_item._id
    ) {
      if (
        //belum ada geojson di dalam layer yang diklik, akses data dahulu
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              flyStatus: true,
              folder_id,
              capex_value: this.props.properties.capex_value,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        //sudah ada geojson hanya fly saja
        this.props.set_layer_id(geo_layer_item._id);
        //kemungkinan 2: sudah ada geojson, langsung toggle view
        const body = { geo_layer_id: geo_layer_item._id, value: true };
        this.props.view_layer(body);
      }
    } else {
      this.props.set_layer_id("");
    }
  };

  //2
  on_view_layer = (geo_layer_item, value, folder_id) => {
    //layer yang saat ini sedang aktif (layer yang diklik bisa jadi sama, bisa jadi beda)
    const { layer_id } = this?.props?.layer;
    if (
      (layer_id !== geo_layer_item?._id &&
        geo_layer_item?.geojson?.features?.length === 0) ||
      !geo_layer_item?.geojson?.features
    ) {
      //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
      if (geo_layer_item?.type_2 === "pch") {
        this.props.get_pch(geo_layer_item);
      } else if (geo_layer_item?.type_2 === "pda") {
        this.props.get_pda(geo_layer_item);
      } else {
        if (geo_layer_item?.is_custom_endpoint) {
          this.props.get_layer_detail_custom_api(geo_layer_item._id);
        } else {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id: folder_id,
            capex_value: this.props.properties.capex_value,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      }
    } else {
      //kemungkinan 2: sudah ada geojson, langsung toggle view
      const body = { geo_layer_id: geo_layer_item._id, value };
      this.props.view_layer(body);
    }
    // this.handle_pre_subscribe_socket(geo_layer_item._id)

    if (value) {
      this.handle_pre_subscribe_socket(geo_layer_item._id);
    } else {
      this.handle_pre_delete_viewer(geo_layer_item._id);
    }
  };

  //3
  on_view_heatmap = (geo_layer_item, value, folder_id) => {
    //layer yang saat ini sedang aktif (layer yang diklik bisa jadi sama, bisa jadi beda)
    const { layer_id } = this?.props?.layer;
    if (
      //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
      (layer_id !== geo_layer_item?._id &&
        geo_layer_item?.geojson?.features?.length === 0) ||
      !geo_layer_item?.geojson?.features
    ) {
      if (geo_layer_item?.type_2 === "pch") {
        this.props.get_pch(geo_layer_item);
      } else if (geo_layer_item?.type_2 === "pda") {
        this.props.get_pda(geo_layer_item);
      } else {
        if (geo_layer_item?.is_custom_endpoint) {
          this.props.get_layer_detail_custom_api(geo_layer_item._id);
        } else {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id,
            capex_value: this.props.properties.capex_value,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      }
    } else {
      //kemungkinan 2: sudah ada geojson, langsung toggle view
      const body = { geo_layer_id: geo_layer_item._id, value };
      this.props.view_heatmap(body);
    }

    if (value) this.handle_pre_subscribe_socket(geo_layer_item._id);
  };

  //4
  toggle_edit_geometry = (geo_layer_item, folder_id) => {
    this.props.set_value_toolbox({
      key: "draw_toolbox_status",
      value: false,
    });
    const { geometryStatus, layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    if (layer_id !== geo_layer_item._id) {
      if (!geo_layer_item?.geojson?.features?.length) {
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const { geo_project } = this.props.project;
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id: folder_id,
              capex_value: this.props.properties.capex_value,
              geometryStatus: !geometryStatus,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
        this.handle_pre_subscribe_socket(geo_layer_item._id);
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setEditGeometry(!geometryStatus);
      }
    } else {
      if (!geo_layer_item?.geojson?.features?.length) {
        const body = {
          geo_layer_id: geo_layer_item._id,
          geo_project_id: geo_project._id,
          folder_id,
          capex_value: this.props.properties.capex_value,
          geometryStatus: !geometryStatus,
          geo_layer_item,
        };
        this.props.getDetailLayerById(body);
        this.handle_pre_subscribe_socket(geo_layer_item._id);
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setEditGeometry(!geometryStatus);
      }
    }
    if (!geometryStatus) this.handle_pre_subscribe_socket(geo_layer_item._id);
  };

  //5
  toggle_edit_property = (geo_layer_item, folder_id) => {
    const { tableStatus, layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    //Jika layer yang diklik bukanlah layer yang sedang aktif--> aktifkan layer
    if (layer_id !== geo_layer_item._id) {
      //Jika layer belum memiliki geojson, ambil geojson via API
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(true);
        //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id,
              capex_value: this.props.properties.capex_value,
              tableStatus: true,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        //Jika layer sudah memiliki geojson --> aktifkan layer tanpa mengambil geojson lagi
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(true);
      }
    } else {
      //Jika layer yang diklik adalah layer yang sedang aktif
      //Jika layer awalnya kosong, coba cek ke API lagi, barangkali sekarang ada isinya
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(!tableStatus);
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id,
              capex_value: this.props.properties.capex_value,
              tableStatus: !tableStatus,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(!tableStatus);
      }
    }

    if (!tableStatus || geo_layer_item._id !== layer_id)
      this.handle_pre_subscribe_socket(geo_layer_item._id);
  };

  toggle_realtime_get_layer = () => {
    let { layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    this.setState({ realtimeState: !this.state.realtimeState }, () => {
      const { realtimeState } = this.state;
      this.props.setRealtimeStatus(realtimeState);
      if (realtimeState) {
        const body = {
          geo_layer_id: layer_id,
          geo_project_id: geo_project._id,
        };
        this.interval = setInterval(() => {
          this.props.getDetailLayerById(body);
        }, 4000);
      } else {
        clearInterval(this.interval);
      }
    });
  };

  toggle_chat_on_layer = (geo_layer_item) => {
    let { geo_layer_wo_geojson, chatStatus } = this.props.layer;
    geo_layer_wo_geojson = geo_layer_wo_geojson?.geo_layer;
    if (geo_layer_wo_geojson?._id !== geo_layer_item?._id) {
      const content = {
        geo_layer: geo_layer_item,
      };
      this.props.setLayerWOgeojson(content);
      // this.props.getDetailLayerByLinkWOgeojson(geo_layer_item.link);
      this.props.setChatStatus(true);
    } else {
      const content = {
        geo_layer: geo_layer_item,
      };
      this.props.setLayerWOgeojson(content);
      this.props.setChatStatus(!chatStatus);
      // if (!chatStatus) {
      //   this.props.getDetailLayerByLinkWOgeojson(geo_layer_item.link);
      // } else {
      // }
    }
  };

  setBodyMigrate = (geo_layer_id, user_id, geo_project_id) => {
    this.setState({
      bodyMigrate: {
        geo_layer_id,
        user_id,
        geo_project_id,
      },
    });
  };

  toggle_edit_general = (geo_layer_item) => {
    this.setState({ modalEdit: !this.state.modalEdit });
    if (geo_layer_item) {
      const content = {
        geo_layer: geo_layer_item,
      };
      this.props.setLayer(content);
    }
  };

  toggle_delete_layer = (geo_layer_item) => {
    if (geo_layer_item) {
      const content = {
        geo_layer: geo_layer_item,
      };
      this.props.setLayer(content);
    }
    this.setState({ modalDelete: !this.state.modalDelete });
  };

  toggle_delete_attached_layer = (geo_layer_item) => {
    if (geo_layer_item) {
      const content = {
        geo_layer: geo_layer_item,
      };
      this.props.setLayer(content);
    }
    this.setState({ modalDeleteAttach: !this.state.modalDeleteAttach });
  };

  handleCheckMarkLayers = (id) => {
    if (id) {
      let { checkedLayers } = this.props;
      const findResult = checkedLayers.findIndex((layerId) => layerId === id);
      if (findResult !== -1) {
        checkedLayers = checkedLayers.filter((layerId) => layerId !== id);
      } else {
        checkedLayers.push(id);
      }
      this.props.setCheckedLayers(checkedLayers);
    }
  };

  isIdInsideChecklistLayers = (id) => {
    let { checkedLayers } = this.props;
    const findResult = checkedLayers.find((layerId) => layerId === id);
    if (findResult) {
      return true;
    } else {
      return false;
    }
  };

  toggleModalMigration = () => {
    const { modalMigration } = this.state;
    this.setState({ modalMigration: !modalMigration });
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  migration = async (e) => {
    const { bodyMigrate } = this.state;
    await this.props.migration_action(bodyMigrate);
  };

  handle_pre_subscribe_socket = (dataId) => {
    const { _id, name, full_name, email, profile_picture } =
      this.props.auth?.user;

    const user_data = {
      _id,
      name,
      full_name,
      email,
      profile_picture,
    };
    this.handle_subscribe_socket(user_data, dataId);
    this.socket.on(`${dataId}`, (data) => {
      this.setState((prevState) => {
        const editorUsers = prevState?.editorUsers || [];
        let topicExists = editorUsers.some((data) => data?.topicId === dataId);
        if (!topicExists) {
          return {
            editorUsers: [...editorUsers, { topicId: dataId, users: data }],
          };
        } else {
          // If the topic exists, update the existing topic's users
          const editorUsers = prevState?.editorUsers || [];
          return {
            editorUsers: editorUsers.map((topic) => {
              if (topic.topicId === dataId) {
                return { ...topic, users: data };
              }
              return topic;
            }),
          };
        }
      });
    });
  };
  handle_pre_delete_viewer = (dataId) => {
    const { _id, name, email, profile_picture } = this.props.auth?.user;

    const user_data = {
      _id,
      name,
      email,
      profile_picture,
    };
    this.handle_unsubscribe_socket(user_data, dataId);
    this.socket.on(`${dataId}`, (data) => {
      this.setState((prevState) => {
        const editorUsers = prevState?.editorUsers || [];
        let findData = editorUsers.filter((data) => data?.topicId === dataId);
        if (data == null) {
          if (findData.length > 0) {
            return {
              editorUsers: editorUsers.filter((user) => user !== findData[0]),
            };
          }
        }
      });
    });
  };

  handle_subscribe_socket = (user, layerId) => {
    // document.addEventListener('mousemove', (event) => this.handleMouseMove(event, user, layerId));
    this.socket.emit("from_mapid_backend", {
      data: { ...user, idLayer: layerId, borderColor: this.getRandomColor() },
      topic: "userUpdate",
    });

    this.setState({ IDLayer: layerId });
    // this.props.onToggleCursor(user, layerId);
  };
  handle_unsubscribe_socket = (user, layerId) => {
    this.socket.emit("from_mapid_backend", {
      data: { ...user, idLayer: layerId },
      topic: "userDelete",
    });

    this.socket.off(`${layerId}`);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    let mode_list = localStorage.mode_list;

    //local state
    const {
      modalEdit,
      modalDelete,
      realtimeState,
      modalDeleteAttach,
      modalMigration,
      modalEditorInfo,
    } = this.state;
    const editorUsers = this?.state?.editorUsers || [];

    //global props
    let {
      geo_layer_wo_geojson,
      geometryStatus,
      tableStatus,
      flyStatus,
      chatStatus,
      layer_id,
      layer_id_active,
      layer_id_heatmap,
    } = this.props.layer;
    const { geo_layer_list, is_mode_checklist_layer } = this.props;
    const domain = this.props.auth.domain;

    //content
    // const longpress = new LongPress();
    if (domain === "bbwsciliwungcisadane.mapid.io") {
      if (!mode_list) {
        mode_list = "compact";
      }
    } else if (domain === "bbwsbrantas.mapid.io") {
      if (!mode_list) {
        mode_list = "compact";
      }
    } else if (
      domain === "ditjenikma.mapid.io" ||
      domain === "ditjenikma-kemenperin.mapid.io"
    ) {
      if (!mode_list) {
        mode_list = "default";
      }
    } else if (domain === "mrt.mapid.io") {
      if (!mode_list) {
        mode_list = "default";
      }
    } else {
      if (!mode_list) {
        mode_list = "default";
      }
    }

    const content = (
      <main>
        {geo_layer_list.map((l, idx) => {
          const folder_id = l?.folder || "";
          const status = l?.status;
          const layer = l?.geo_layer;
          const { _id, name, user, architecture, geo_project } = layer || {};
          const { full_name, profile_picture } = user || {};

          const viewStatus = layer_id_active.includes(layer?._id);
          const view_heatmap = layer_id_heatmap.includes(layer?._id);

          const username = user?.name;
          let realtimeButton;
          if (_id === layer_id && tableStatus) {
            realtimeButton = (
              <CheckBox
                text="realtimeState"
                title=""
                value={realtimeState}
                handle={this.toggle_realtime_get_layer.bind(this)}
              />
            );
          } else {
            realtimeButton = null;
          }
          let id_css;
          if (_id === layer_id && flyStatus) {
            id_css = "box_active";
          } else {
            id_css = "box_non_active";
          }
          let button_footer;
          let button_top;

          const addRowBulkButton = (
            <div style={{ display: "inline-block", marginRight: "5px" }}>
              <AddRowBulk geo_layer={layer} />
            </div>
          );

          const editLayerButton = (
            <div
              onClick={this.toggle_edit_general.bind(this, layer)}
              style={{ display: "inline-block" }}
            >
              <EditIcon />
            </div>
          );

          const deleteLayerButton = (
            <div
              onClick={
                status === "attached"
                  ? this.toggle_delete_attached_layer.bind(this, layer)
                  : this.toggle_delete_layer.bind(this, layer)
              }
              style={{ display: "inline-block" }}
            >
              <DeleteIcon />
            </div>
          );

          button_top = (
            <section style={{ display: "inline-block", gap: "10px" }}>
              {geo_layer_status_that_editable_properties.includes(status) &&
                architecture !== "api" &&
                addRowBulkButton}
              {geo_layer_status_that_editable_layer.includes(status) &&
                editLayerButton}
              {geo_layer_status_that_deleteable.includes(status) &&
                deleteLayerButton}
            </section>
          );

          button_footer = (
            <section className="five-container-no-gap h_35">
              {viewStatus ? (
                <div
                  className="buttonCenter noselect button_inactive"
                  style={{
                    background: `${viewStatus ? "#606f8dff" : "#e5eefdff"}`,
                  }}
                  onClick={this.on_view_layer.bind(
                    this,
                    layer,
                    false,
                    folder_id
                  )}
                >
                  <img
                    src={viewStatus ? icon_view_white : icon_view}
                    alt="view layer"
                    className="icon_list_render"
                  />
                </div>
              ) : (
                <div
                  className="buttonCenter noselect button_inactive"
                  style={{
                    background: `${viewStatus ? "#606f8dff" : "#e5eefdff"}`,
                  }}
                  onClick={this.on_view_layer.bind(
                    this,
                    layer,
                    true,
                    folder_id
                  )}
                >
                  <img
                    src={viewStatus ? icon_view_white : icon_view}
                    alt="view layer"
                    className="icon_list_render"
                  />
                </div>
              )}
              {view_heatmap ? (
                <button
                  className="buttonCenter button_inactive"
                  style={{
                    background: "#606f8d",
                  }}
                  onClick={this.on_view_heatmap.bind(
                    this,
                    layer,
                    false,
                    folder_id
                  )}
                >
                  <img
                    src={icon_heatmap_white}
                    alt="View"
                    className="icon_list_render"
                  />
                </button>
              ) : (
                <button
                  className="buttonCenter button_inactive"
                  style={{
                    background: "#e5eefdff",
                  }}
                  onClick={this.on_view_heatmap.bind(
                    this,
                    layer,
                    true,
                    folder_id
                  )}
                >
                  <img
                    src={icon_heatmap_black}
                    alt="View"
                    className="icon_list_render"
                  />
                </button>
              )}
              {geo_layer_status_that_editable_properties.includes(status) &&
                architecture !== "api" && (
                  <div
                    className="buttonCenter noselect button_inactive"
                    style={{
                      background: `${
                        _id === layer_id && geometryStatus
                          ? "#606f8dff"
                          : "#e5eefdff"
                      }`,
                    }}
                    onClick={this.toggle_edit_geometry.bind(
                      this,
                      layer,
                      folder_id
                    )}
                  >
                    <img
                      src={
                        _id === layer_id && geometryStatus
                          ? icon_edit_geometry_white
                          : icon_edit_geometry
                      }
                      alt="edit geometry"
                      className="icon_list_render"
                    />
                  </div>
                )}
              <div
                className="buttonCenter noselect button_inactive"
                style={{
                  background: `${
                    _id === layer_id && tableStatus ? "#606f8dff" : "#e5eefdff"
                  }`,
                }}
                onClick={this.toggle_edit_property.bind(this, layer, folder_id)}
              >
                <img
                  src={
                    _id === layer_id && tableStatus
                      ? icon_edit_properties_white
                      : icon_edit_properties
                  }
                  alt="edit properties"
                  className="icon_list_render"
                />
              </div>
              <div
                className="buttonCenter noselect button_inactive"
                style={{
                  background:
                    _id === geo_layer_wo_geojson?.geo_layer?._id && chatStatus
                      ? "#606f8dff"
                      : "#e5eefdff",
                }}
                onClick={this.toggle_chat_on_layer.bind(this, layer)}
              >
                <img
                  src={
                    _id === geo_layer_wo_geojson?.geo_layer?._id && chatStatus
                      ? icon_chat_white
                      : icon_chat
                  }
                  alt="chat"
                  className="icon_list_render"
                />
              </div>
            </section>
          );

          const info_content = (
            <section>
              {status !== "original" && (
                <div className="button_very_small" id="blue">
                  {dict?.[status]?.[language]
                    ? dict?.[status]?.[language]
                    : dict?.["other"]?.[language]}
                </div>
              )}
            </section>
          );

          const iot_content = (
            <section>
              {layer?.type === "IoT" ? (
                <section style={{ fontSize: 12, marginBottom: "10px" }}>
                  Type :
                  <div style={{ display: "inline-block", marginLeft: "5px" }}>
                    <b> IoT </b>
                    <button
                      className="button_circle"
                      style={{
                        fontSize: "13px",
                        width: "20px",
                        height: "20px",
                      }}
                      id="outline_blue"
                      onClick={this.onOpenModal.bind(
                        this,
                        "Create Layer (IoT)"
                      )}
                    >
                      i
                    </button>
                  </div>
                </section>
              ) : (
                <section style={{ fontSize: 12, marginBottom: "10px" }}>
                  {dict?.["Type"]?.[language]} : {layer?.type}
                </section>
              )}
            </section>
          );

          //khusus_danamas
          const migration_content = (
            <div>
              {architecture !== "v2" &&
              architecture !== "api" &&
              process.env.REACT_APP_ARCHITECTURE === "v2" &&
              domain !== "danamas.mapid.io" ? (
                <button
                  className="buttonCenter noselect long_button_inactive"
                  style={{
                    color: `${viewStatus ? "#ffffff" : "#000000"}`,
                    backgroundColor: `${
                      viewStatus ? "#606f8dff" : "#e5eefdff"
                    }`,
                    width: "100%",
                  }}
                  onClick={() => {
                    this.toggleModalMigration();
                    this.setBodyMigrate(_id, user?._id, geo_project?._id);
                  }}
                >
                  Migration Layer
                </button>
              ) : (
                ""
              )}
            </div>
          );

          let final_content = (
            <main>
              <section className="layer_name flex justify_between noselect">
                <div className="flex w_full justify_between" title={name}>
                  <span className="min_h_20 w_220">{name}</span>
                </div>
                {is_mode_checklist_layer && (
                  <div
                    onClick={() => {
                      this.handleCheckMarkLayers(_id);
                    }}
                    className={`pointer center_perfect border h_20 w_20 rounded_5 marginRight_0 ${
                      this.isIdInsideChecklistLayers(_id)
                        ? "bg_blue"
                        : "bg_white"
                    }`}
                  >
                    {this.isIdInsideChecklistLayers(_id) && (
                      <strong className="bold text_white">✔</strong>
                    )}
                  </div>
                )}
              </section>
              <div className="flex">
                <div style={{ flex: 1 }}>{iot_content}</div>
                <div className="flex justify_between gap_5">
                  {info_content}
                  <section>
                    <div className="button_very_small bg_green text_white">
                      <label>
                        {architecture ? architecture.toUpperCase() : "-"}
                      </label>
                    </div>
                  </section>
                </div>
              </div>
              <section
                style={{
                  textAlign: "left",
                  marginBottom: "10px",
                  cursor: "default",
                }}
              >
                <div
                  alt={_id}
                  className={`photo_${_id}`}
                  samesite="None"
                  secure="true"
                />
                <p
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {full_name && full_name !== "" && full_name !== "-"
                    ? full_name
                    : username}
                </p>
                <style>
                  {`.photo_${_id}{
                  background: url(${
                    profile_picture && profile_picture?.url_compressed
                      ? profile_picture?.url_compressed
                      : profile_picture &&
                        profile_picture?.url &&
                        profile_picture?.url !==
                          "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                        profile_picture?.url !== "-" &&
                        profile_picture?.url !== "default"
                      ? profile_picture?.url
                      : pic_static
                  }) no-repeat center center/ cover;
                  background-color: #c1c1c1;
                  height:40px;
                  width:40px;
                  border-radius: 50%;
                  border: 2px solid #2196f3;
                  display: inline-block;
                  align: center;
                  vertical-align: middle;
    }`}
                </style>
              </section>

              <div style={{ textAlign: "right", marginBottom: 6 }}>
                {button_top}
              </div>

              {button_footer}

              <div>
                {(geometryStatus ||
                  viewStatus ||
                  view_heatmap ||
                  chatStatus ||
                  tableStatus) &&
                editorUsers.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {editorUsers.some((data) => data.topicId === _id) ? (
                        <p
                          style={{
                            display: "inline-block",
                            marginLeft: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {dict["On Editing"][language] + "..."}
                        </p>
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {editorUsers
                          .find((user) => user?.topicId === _id)
                          ?.users.slice(0, this?.state?.maxViewEditors)
                          .map((data, index) => (
                            <div
                              key={data._id}
                              className={`photo_${data._id}`}
                              style={{
                                position: "relative",
                                marginLeft: index !== 0 ? "-10px" : "0",
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                border: `2px solid ${
                                  data.borderColor || this.getRandomColor()
                                }`,
                                background: `url(${
                                  data.profile_picture.url || pic_static
                                }) no-repeat center center / cover`,
                              }}
                              onClick={() => {
                                this.handleModalEditorUser(data);
                              }}
                            ></div>
                          ))}
                        {(editorUsers.find((user) => user.topicId === _id)
                          ?.users.length ?? 0) >
                          this?.state?.maxViewEditors && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                              border: "2px solid #2196f3",
                              backgroundColor: "#c1c1c1",
                              color: "#fff",
                              fontWeight: "bold",
                              position: "relative",
                              marginLeft: "-10px",
                            }}
                          >
                            +
                            {editorUsers.find((user) => user.topicId === _id)
                              ?.users.length - this?.state?.maxViewEditors}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              {realtimeButton}
              {migration_content}
            </main>
          );
          if (mode_list === "compact") {
            final_content = (
              <main>
                <section className="layer_name flex justify_between noselect ">
                  <div
                    className="w_220"
                    style={{ display: "inline-block", cursor: "pointer" }}
                    onClick={this.toggle_fly_layer.bind(this, layer, folder_id)}
                    title={name}
                  >
                    {name}
                  </div>
                  {is_mode_checklist_layer && (
                    <div
                      onClick={() => {
                        this.handleCheckMarkLayers(_id);
                      }}
                      className={`pointer center_perfect border h_20 w_20 rounded_5 marginRight_0 ${
                        this.isIdInsideChecklistLayers(_id)
                          ? "bg_blue"
                          : "bg_white"
                      }`}
                    >
                      {this.isIdInsideChecklistLayers(_id) && (
                        <strong className="bold text_white">✔</strong>
                      )}
                    </div>
                  )}
                </section>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>{iot_content}</div>
                  {info_content}
                </div>
                <div style={{ textAlign: "right", marginBottom: 6 }}>
                  {button_top}
                </div>

                {button_footer}
                {realtimeButton}
              </main>
            );
          } else if (mode_list === "title_only") {
            final_content = (
              <main>
                <section className="layer_name flex justify_between noselect ">
                  <div
                    className="w_220"
                    style={{ display: "inline-block", cursor: "pointer" }}
                    onClick={this.toggle_fly_layer.bind(this, layer, folder_id)}
                    title={name}
                  >
                    {name}
                  </div>
                  {is_mode_checklist_layer && (
                    <div
                      onClick={() => {
                        this.handleCheckMarkLayers(_id);
                      }}
                      className={`pointer center_perfect border h_20 w_20 rounded_5 marginRight_0 ${
                        this.isIdInsideChecklistLayers(_id)
                          ? "bg_blue"
                          : "bg_white"
                      }`}
                    >
                      {this.isIdInsideChecklistLayers(_id) && (
                        <strong className="bold text_white">✔</strong>
                      )}
                    </div>
                  )}
                </section>
                <div style={{ textAlign: "right", marginBottom: 6 }}>
                  {button_top}
                </div>

                {button_footer}
                {realtimeButton}
              </main>
            );
          }

          return (
            <LongPress
              key={idx}
              id={id_css}
              className="layer_box"
              delay={800}
              onLongPress={() => {
                if (!this.props.is_mode_checklist_layer) {
                  this.props.toggleIsModeChecklistLayer();
                }
                this.handleCheckMarkLayers(_id);
              }}
            >
              {final_content}
              {layer?.is_premium && (
                <div className="button_small margin_top" id="green">
                  Rp
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(layer?.price | 0)}
                </div>
              )}
            </LongPress>
          );
        })}
      </main>
    );

    const modalEditContent = modalEdit && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modalEdit}
        onClose={this.toggle_edit_general}
      >
        <div className="box-body">
          <EditLayer toggle_edit_general={this.toggle_edit_general} />
        </div>
      </Modal>
    );
    const modalEditorInf = modalEditorInfo && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditorInfo}
        onClose={this.handleModalEditorUser.bind(this)}
      >
        <p style={{ marginBottom: "10px" }}>List Editor </p>
        <div className="box-body">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {editorUsers
              .filter(
                (user) => user.topicId === this.state.modalEditorData.idLayer
              )[0]
              .users.map((user) => (
                <div
                  key={user._id}
                  style={{
                    display: "flex",
                    flexDirection: "row", // Arrange items in a row
                    alignItems: "center",
                    marginBottom: "10px", // Add some spacing between items
                  }}
                >
                  <div
                    style={{
                      marginRight: "10px", // Space between photo and name
                    }}
                  >
                    <div
                      className={`photo_${user._id}`}
                      samesite="None"
                      secure="true"
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        border: `2px solid ${user?.borderColor}`,
                        background: `url(${
                          user.profile_picture.url
                            ? user.profile_picture.url
                            : pic_static
                        }) no-repeat center center / cover`,
                      }}
                    />
                  </div>
                  <div>
                    <p>{user?.full_name}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    );

    const modalDeleteContent = modalDelete && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalDelete}
        onClose={this.toggle_delete_layer}
      >
        <div className="box-body">
          <DeleteLayer toggle_delete_layer={this.toggle_delete_layer} />
        </div>
      </Modal>
    );

    const modalDeleteAttachContent = modalDeleteAttach && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalDeleteAttach}
        onClose={this.toggle_delete_attached_layer}
      >
        <div className="box-body">
          <DeleteLayerAttach
            toggle_delete_layer={this.toggle_delete_attached_layer}
          />
        </div>
      </Modal>
    );

    const modalMigrationContent = modalMigration && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalMigration}
        onClose={this.toggleModalMigration}
      >
        <main className="flex flex_col gap_20">
          <section className="text_center font_20">
            {dict["Do you really want to migrate this layer to V2?"][language]}
          </section>
          <section className="center_perfect">
            <div className="flex gap_5">
              <button
                className="button button_inactive bg_blue text_white"
                onClick={async () => {
                  await this.migration();
                  this.setState({
                    modalMigration: false,
                  });
                }}
              >
                {dict["Yes"][language]}
              </button>
              <button
                className="button button_inactive bg_red text_white"
                onClick={this.toggleModalMigration}
              >
                {dict["No"][language]}
              </button>
            </div>
          </section>
        </main>
      </Modal>
    );

    return (
      <>
        {content}
        {modalEditContent}
        {modalEditorInf}
        {modalDeleteContent}
        {modalDeleteAttachContent}
        {modalMigrationContent}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  setLayer,
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
  setLayerWOgeojson,
  setChatStatus,
  getDetailLayerByLinkWOgeojson,
  openModal,
  getDetailLayerById,
  setDrawMode,
  setViewLayer,
  view_layer,
  set_layer_id,
  view_heatmap,
  migration_action,
  set_value_layer,
  get_pda,
  get_pch,
  get_layer_detail_custom_api,
  set_value_toolbox,
})(LayerListRender);
